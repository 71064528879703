/*
  @author Alistair Hill
  @description Global actions
*/
const setLoading = (loading: boolean) =>
  ({
    type: 'SET_LOADING',
    payload: loading,
  } as const)

const setSnackbar = (message: string = '') =>
  ({
    type: 'SET_SNACKBAR',
    payload: message,
  } as const)

const resetGlobal = () =>
  ({
    type: 'RESET_GLOBAL',
  } as const)

const setOrgNotRequired = (orgNotRequired: boolean) =>
  ({
    type: 'SET_ORG_NOT_REQUIRED',
    payload: orgNotRequired,
  } as const)

const setSupplierRequired = (supplierRequired: boolean) =>
  ({
    type: 'SET_SUPPLIER_REQUIRED',
    payload: supplierRequired,
  } as const)

export type GlobalActions = ReturnType<
  | typeof setLoading
  | typeof setSnackbar
  | typeof resetGlobal
  | typeof setOrgNotRequired
  | typeof setSupplierRequired
>

export {
  setLoading,
  setSnackbar,
  resetGlobal,
  setOrgNotRequired,
  setSupplierRequired,
}
