/*
  @author Alistair Hill
  @description redux store
*/
import {combineReducers} from 'redux'

import {organizationReducer, OrganizationState} from './reducers'
import {supplierReducer, SupplierState} from './reducers'
import {categoryReducer, CategoryState} from './reducers'
import {facilityReducer, FacilityState} from './reducers'
import {globalReducer, GlobalState} from './reducers'

export type ReduxStateType = {
  organization: OrganizationState
  supplier: SupplierState
  category: CategoryState
  facility: FacilityState
  global: GlobalState
}

export const rootReducer = combineReducers({
  organization: organizationReducer,
  supplier: supplierReducer,
  category: categoryReducer,
  facility: facilityReducer,
  global: globalReducer,
})

export type RootState = ReturnType<typeof rootReducer>
