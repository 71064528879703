/* eslint-disable */
import * as Types from '../types.generated'

import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {fetchData} from 'utility/reactQuery'
export type UpdateFeatureFlagsMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['Int']
  featureFlags: Array<Types.FeatureFlagModelInput> | Types.FeatureFlagModelInput
}>

export type UpdateFeatureFlagsMutation = {
  __typename?: 'Mutation'
  updateFeatureFlags: Array<{
    __typename?: 'FeatureFlag'
    organizationId: number
    featureId: number
    access?: boolean | null
    feature?: {
      __typename?: 'Feature'
      id: number
      name?: string | null
      isBeta: boolean
    } | null
  }>
}

export const UpdateFeatureFlagsDocument = `
    mutation updateFeatureFlags($organizationId: Int!, $featureFlags: [FeatureFlagModelInput!]!) {
  updateFeatureFlags(organizationId: $organizationId, featureFlags: $featureFlags) {
    organizationId
    featureId
    feature {
      id
      name
      isBeta
    }
    access
  }
}
    `
export const useUpdateFeatureFlagsMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateFeatureFlagsMutation,
    TError,
    UpdateFeatureFlagsMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateFeatureFlagsMutation,
    TError,
    UpdateFeatureFlagsMutationVariables,
    TContext
  >(
    ['updateFeatureFlags'],
    (variables?: UpdateFeatureFlagsMutationVariables) =>
      fetchData<
        UpdateFeatureFlagsMutation,
        UpdateFeatureFlagsMutationVariables
      >(UpdateFeatureFlagsDocument, variables)(),
    options
  )
