import {PublicClientApplication} from '@azure/msal-browser'
import {config} from 'config'

// Directory (tenant) ID
const tenant = config.adTenantId

// Application (client) ID
const clientId = config.adClientId!
// Authority
const cloudInstance = 'https://login.microsoftonline.com'

export const msalConfig = {
  auth: {
    clientId,
    authority: `${cloudInstance}/${tenant}`,
    redirectUri:
      process.env.NODE_ENV !== 'production' ? 'http://localhost:3000' : '',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['api://4eaeadd0-0a40-4b43-bb04-ce08bbb3b147/User.Read'],
}

export const msalInstance = new PublicClientApplication(msalConfig)

export const getMSALUserAccount = () => msalInstance.getAllAccounts()[0]

export const getToken = async () => {
  const account = getMSALUserAccount()
  if (account) {
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account,
    })
    return response.accessToken
  }
  return ''
}
