import {config} from '../config'
import {ValueOf} from 'types/valueOf'
import {COMPLETED, NEEDS_MAPPING, NEEDS_REVIEW} from 'constants/constants'
import {ConductivFeaturesEnum} from '../graphql/types.generated'

export const clearEmptyValues = <T>(
  query: Record<string, T> | {[key: string]: T}
) =>
  Object.keys(query).reduce((prev, curr) => {
    if (curr && query[curr]) {
      return {
        ...prev,
        [curr]: query[curr],
      }
    }

    return prev as any
  }, {} as Record<string, string>)

type Config = typeof config

const pathQueryHelper = (configUrl?: Config[keyof Config]) => (
  path: string,
  organizationIdOrQuery?: number | Record<string, string>
) => {
  if (typeof organizationIdOrQuery === 'number') {
    return `${configUrl}${path}?organizationId=${organizationIdOrQuery}`
  }

  if (organizationIdOrQuery) {
    return `${configUrl}${path}?${new URLSearchParams(
      clearEmptyValues(organizationIdOrQuery)
    ).toString()}`
  }

  return `${configUrl}${path}`
}

export const getMsourceUrl = pathQueryHelper(config.msourceUrl)
export const getAdminPanelUrl = pathQueryHelper(config.adminGatewayUrl)

// Helper to simulate server delay
export const delayPromise = (duration: number) => (...args: any) =>
  new Promise((resolve: any) => setTimeout(() => resolve(...args), duration))

/**
 * createFetchingCache for when you only want 1 request per async action at a time
 * @returns isOrSetFetching
 * @usage see below
 */
export const createFetchingCache = <K = string>() => {
  const fetchingCache = new Map<K, boolean>()

  /**
   * isOrSetFetching
   * @usage
   * const { isFetching, clear } = isOrSetFetching(fetchFilesKey)
   *  if (isFetching) return
   *  ... later
   *  clear() // when done or error
   */
  return (
    key: K,
    isFetchingMsg: null | string = `Currently fetching ${key}`
  ) => {
    const isFetching = !!fetchingCache.get(key)
    if (isFetching) {
      if (isFetchingMsg !== null) console.warn(isFetchingMsg)
    } else {
      fetchingCache.set(key, true)
    }

    return {
      isFetching,
      clear: () => fetchingCache.set(key, false),
    }
  }
}

export const formatCurrency = (num: number, excludeDecimal?: boolean) =>
  excludeDecimal
    ? num && num.toFixed().replace(/\d(?=(\d{3})+$)/g, '$&,')
    : num && num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

export const shortenString = (data: string, length: number = 35) => {
  const ellipsis = data?.length > length ? '...' : ''

  return data && data.substring(0, length) + ellipsis
}

export const isValidDate = (value: string | undefined): boolean => {
  if (value && value !== '') {
    return !isNaN(Date.parse(value))
  }
  return false
}

export const isValidEmail = (value: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(value)
}

export const parseDate = (input: string | undefined): Date | undefined => {
  if (input && input !== '') {
    const parts = input.match(/(\d+)/g)
    if (parts && parts.length >= 3) {
      return new Date(
        Number(parts![0]),
        Number(parts![1]) - 1,
        Number(parts![2])
      ) // months are 0-based
    }
  }
  return undefined
}

export const toUsDate = (date: Date | string) =>
  new Date(date && date).toLocaleDateString('en-US')

export const isEmpty = (obj?: Object | any[]) => {
  for (const item in obj) {
    return false
  }

  return true
}

// From mp.msource.frontend
export const ascending = <T>(valueSelector: (thing: T) => ValueOf<T>) => (
  a: T,
  b: T
) =>
  ((x: ValueOf<T>, y: ValueOf<T>) => +(x > y) - +(x < y))(
    valueSelector(a),
    valueSelector(b)
  )

// From mp.msource.frontend
export const descending = <T>(valueSelector: (thing: T) => ValueOf<T>) => (
  a: T,
  b: T
) =>
  ((x: ValueOf<T>, y: ValueOf<T>) => +(x < y) - +(x > y))(
    valueSelector(a),
    valueSelector(b)
  )

type CurryHelper = {
  func: Function
  args: any[]
  prevArgs: any[]
}

export const curryGenerator = (fn: Function) => {
  const helper = ({func, args, prevArgs}: CurryHelper) => {
    if (args.length + prevArgs.length >= func.length) {
      return func(...prevArgs, ...args)
    }
    return (...newArgs: any[]) =>
      helper({
        args: newArgs,
        prevArgs: [...prevArgs, ...args],
        func,
      })
  }
  return (...args: any[]) =>
    helper({
      func: fn,
      args: args,
      prevArgs: [],
    })
}

export const infiniteCurry = (fn: Function, seed: any) => {
  const reduceValue = (args: any, seedValue: any) =>
    args.reduce((acc: any, a: any) => {
      return fn.call(fn, acc, a)
    }, seedValue)

  const next = (...args: any) => (...x: any) => {
    if (!x.length) {
      return reduceValue(args, seed)
    }

    return next(...args, reduceValue(x, seed))
  }

  return next()
}

export const uniqueCsvString = (csvString: string) => {
  return Array.from(new Set(csvString?.split(', '))).join(', ')
}

export const getStatusLabel = (
  status: string,
  isReleasing: boolean = false
) => {
  if (status === NEEDS_MAPPING) {
    return 'Needs mapping'
  } else if (status === NEEDS_REVIEW) {
    return 'Needs Review'
  } else if (status === COMPLETED && isReleasing) {
    return 'Verifying'
  } else {
    return status
  }
}

export const fileNameFromUrl = (url: string | undefined): string => {
  if (url && url !== '') {
    try {
      const pathname = new URL(url).pathname
      const index = pathname.lastIndexOf('/')
      return index > 0 ? pathname.substring(index + 1) : url
    } catch (error) {
      return url
    }
  } else {
    return ''
  }
}

export const removeFileExtension = (
  fileName: string | undefined | null
): string => {
  if (fileName && fileName !== '' && fileName.includes('.')) {
    try {
      const index = fileName.lastIndexOf('.')
      return index >= 0 ? fileName.substring(0, index) : fileName
    } catch (error) {
      return fileName
    }
  } else {
    return ''
  }
}

export const deepEqual = (x: any, y: any): boolean => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y
}

export const getMinutesSince = (milliseconds?: number | null) => {
  if (milliseconds) {
    const timeSince = new Date().getTime() - milliseconds
    const minutes = Math.floor(timeSince / 60000)
    const seconds = ((timeSince % 60000) / 1000).toFixed(0)
    return minutes + ':' + (parseInt(seconds) < 10 ? '0' : '') + seconds
  } else {
    return 0
  }
}

export const hasConductivGlobalFeature = (
  enabledConductivFeatures?: ConductivFeaturesEnum[]
) => (conductivFeature: ConductivFeaturesEnum) =>
  enabledConductivFeatures &&
  enabledConductivFeatures.some((x) => x === conductivFeature)
