import {Organization} from 'types'

class CurrentOrganization {
  private OrganizationId: number | undefined
  private Organization: Organization | undefined

  set organization(org: Organization | undefined) {
    if (org?.id) {
      this.Organization = org
      this.OrganizationId = org.id
      sessionStorage.setItem('selectedOrg', JSON.stringify(org))
    }
  }

  get organization(): Organization | undefined {
    if (!this.Organization) {
      try {
        const sessionOrgStr = sessionStorage.getItem('selectedOrg')
        if (sessionOrgStr) {
          const org = JSON.parse(sessionOrgStr)
          if (org) {
            this.Organization = org
          }
        }
      } catch {
        this.Organization = undefined
      }
    }

    return this.Organization
  }

  get organizationId() {
    return this.OrganizationId
  }
}

const currentOrganization = new CurrentOrganization()
export default currentOrganization
