import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import {createStore} from 'redux'

import {InteractionType} from '@azure/msal-browser'
import {MsalAuthenticationTemplate, MsalProvider} from '@azure/msal-react'
import {LicenseInfo} from '@mui/x-license-pro'

import {loginRequest, msalInstance} from 'auth'
import App from 'components/app/app'
import {config} from 'config'
import {rootReducer} from 'store'
// eslint-disable-next-line import/no-namespace
import * as serviceWorker from 'serviceWorker'
import './index.css'

if (config?.muiLicenseKey) {
  LicenseInfo.setLicenseKey(config.muiLicenseKey)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any
  }
}

const container: Element | null = document.getElementById('root')
const root = createRoot(container!)

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{
          ...loginRequest,
          redirectUri: window.location.origin,
          redirectStartPage: window.location.href,
        }}>
        <App />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
