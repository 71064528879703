/*
  @author Alistair Hill
  @description Global reducer
*/
import produce from 'immer'

import {GlobalActions} from 'store/actions'

export type GlobalState = {
  spinCounter: number
  snackbarText: string
  showSnackbar: boolean
  orgNotRequired: boolean
  supplierRequired: boolean
}

const initialState: GlobalState = {
  spinCounter: 0,
  snackbarText: '',
  showSnackbar: false,
  orgNotRequired: false,
  supplierRequired: false,
}

export const globalReducer = (
  state: GlobalState = initialState,
  action: GlobalActions
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_LOADING':
        return {
          ...draft,
          spinCounter: action.payload ? 1 : 0,
        }
      case 'SET_SNACKBAR':
        return {
          ...draft,
          showSnackbar: !!action.payload,
          snackbarText: action.payload,
        }
      case 'RESET_GLOBAL':
        return initialState
      case 'SET_ORG_NOT_REQUIRED':
        return {
          ...draft,
          orgNotRequired: action.payload,
        }
      case 'SET_SUPPLIER_REQUIRED':
        return {
          ...draft,
          supplierRequired: action.payload,
        }
      default:
        return draft
    }
  })
}
