import {request, paramsWithOrgId} from 'api'

const supplierPerformanceUrl = 'api/supplierperformance'

type GlobalMessage = {type: string; message: string}
type FieldMessage = {type: string; fieldId: string; message: string}

type AddCategoryKpiRequest = {
  categoryId: number
  name: string
  goal: string
  description: string
  kpiType: 'internal' | 'external'
  suppliers: string[]
  origin: string
  reporterEmail: string
}

type AddCategoryKpiResponse = {
  globalMessages: GlobalMessage[]
  fieldMessages: FieldMessage[]
}

export const addCategoryKpi = async (
  orgId: number,
  kpi: AddCategoryKpiRequest
): Promise<
  | {
      success: true
    }
  | {
      success: false
      errors: FieldMessage[]
    }
> => {
  const {data} = await request().post<AddCategoryKpiResponse>(
    `${supplierPerformanceUrl}/kpis`,
    kpi,
    paramsWithOrgId(orgId)
  )
  if (data.globalMessages.some((x) => x.type === 'Success')) {
    return {
      success: true,
    }
  } else {
    return {
      success: false,
      errors: data.fieldMessages,
    }
  }
}

type AddEditCategoryKPIReporterRequest = {
  id?: number
  categoryId: number
  supplierName: string
  reporterEmail: string
}
type AddEditCategoryKPIReporterResponse = {
  globalMessages: GlobalMessage[]
  fieldMessages: FieldMessage[]
}

export const addEditCategoryKPIReporter = async (
  orgId: number,
  req: AddEditCategoryKPIReporterRequest
): Promise<
  | {
      success: true
    }
  | {
      success: false
      errors: FieldMessage[]
    }
> => {
  const {data} = await request().post<AddEditCategoryKPIReporterResponse>(
    `${supplierPerformanceUrl}/reporter`,
    req,
    paramsWithOrgId(orgId)
  )
  if (data.globalMessages.some((x) => x.type === 'Success')) {
    return {
      success: true,
    }
  } else {
    return {
      success: false,
      errors: data.fieldMessages,
    }
  }
}
