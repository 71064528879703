import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import {ReactPlugin} from '@microsoft/applicationinsights-react-js'
import {config} from 'config'

const reactPlugin = new ReactPlugin()
// eslint-disable-next-line import/no-mutable-exports
let appInsights

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

// Check for manual override
const forceTelemetry = process.env.REACT_APP_FORCE_TELEMETRY === 'true'

if (!isLocalhost || forceTelemetry) {
  try {
    if (!config.appInsightsKey) {
      throw new Error('Application Insights connection string is missing.')
    }
    appInsights = new ApplicationInsights({
      config: {
        connectionString: config.appInsightsKey,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        extensions: [reactPlugin],
      },
    })
    appInsights.loadAppInsights()
    appInsights.trackPageView()
  } catch (error) {
    console.error(
      'Failed to initialize Application Insights: ',
      (error as Error).message
    )
    // Provide stub or minimal implementations as a fallback
    appInsights = {
      loadAppInsights: () => {},
      trackPageView: () => {},
      addTelemetryInitializer: () => {},
      setAuthenticatedUserContext: () => {},
    }
  }
} else {
  // Provide stub or minimal implementations for local development
  appInsights = {
    loadAppInsights: () => {},
    trackPageView: () => {},
    addTelemetryInitializer: () => {},
    setAuthenticatedUserContext: () => {},
  }
}
// The below assumes that we want to label the PageViews events by using the second segment of the path as the name of the page view.
// If these names are not descriptive enough, we may need to add additional logic to pull a different name from another source.

// Function to capitalize the first letter of each word and replace dashes with spaces
function formatPageViewName(segment: string): string {
  return segment
    .split('-')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(' ')
}
appInsights.addTelemetryInitializer((item) => {
  // Omit any telemetry that contains "#code" in the URL
  if (item.baseData?.uri && item.baseData.uri.includes('#code')) {
    return false
  }
  return true
})
appInsights.addTelemetryInitializer((item) => {
  if (item.baseType === 'PageviewData' && item.baseData) {
    // Split the pathname into parts and remove any empty segments
    const pathParts = window.location.pathname.split('/').filter(Boolean)
    // Use only the second segment of the path for the page view name, if it exists
    const pageViewName = pathParts.length > 1 ? pathParts[1] : pathParts[0]
    // Format the page view name to replace dashes and capitalize words
    const formattedPageViewName = formatPageViewName(pageViewName)
    // Set the page view name to the formatted second segment of the path
    item.baseData.name = formattedPageViewName
  }
})

export {appInsights, reactPlugin}
