/* eslint-disable */
import * as Types from '../types.generated'

import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {fetchData} from 'utility/reactQuery'
export type GetSuppliersPagingQueryVariables = Types.Exact<{
  searchTerms: Types.Scalars['String']
}>

export type GetSuppliersPagingQuery = {
  __typename?: 'Query'
  suppliersPaging?: {
    __typename?: 'SuppliersPagingConnection'
    nodes?: Array<{
      __typename?: 'SupplierEntity'
      id: number
      name?: string | null
    }> | null
  } | null
}

export const GetSuppliersPagingDocument = `
    query getSuppliersPaging($searchTerms: String!) {
  suppliersPaging(
    first: 25
    where: {name: {contains: $searchTerms}}
    order: [{name: ASC}]
  ) {
    nodes {
      id
      name
    }
  }
}
    `
export const useGetSuppliersPagingQuery = <
  TData = GetSuppliersPagingQuery,
  TError = unknown
>(
  variables: GetSuppliersPagingQueryVariables,
  options?: UseQueryOptions<GetSuppliersPagingQuery, TError, TData>
) =>
  useQuery<GetSuppliersPagingQuery, TError, TData>(
    ['getSuppliersPaging', variables],
    fetchData<GetSuppliersPagingQuery, GetSuppliersPagingQueryVariables>(
      GetSuppliersPagingDocument,
      variables
    ),
    options
  )
