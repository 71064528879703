/*
  @author Alistair Hill
  @description Routes
*/
import React, {lazy} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'

const Landing = lazy(() => import('../components/pages/landing/landing'))

const PredictivePlan = lazy(() =>
  import('../components/pages/predictivePlan/predictivePlan')
)
const ConductivContracts = lazy(() =>
  import('../components/pages/conductivContracts/conductivContracts')
)
const ContractIntelligence = lazy(() =>
  import('../components/pages/contractIntelligence/contractIntelligence')
)
const ContractExplorer = lazy(() =>
  import('../components/pages/contractExplorer/contractExplorer')
)
const MasterData = lazy(() =>
  import('../components/pages/masterData/masterData')
)
const Organizations = lazy(() =>
  import('../components/pages/organizations/organizations')
)

const Suppliers = lazy(() => import('../components/pages/suppliers/suppliers'))

export const routes = {
  root: '/',
  conductivContracts: '/conductiv-contracts',
  contractIntelligence: '/contract-intelligence',
  contractExplorer: '/contract-explorer',
  masterData: '/master-data',
  predictivePlan: '/predictive-plan',
  organizations: '/organizations',
  suppliers: '/suppliers',
}

export const AdminPanelRouter = () => (
  <Switch>
    <Route exact component={Landing} path={routes.root} />
    <Route exact component={PredictivePlan} path={routes.predictivePlan} />
    <Route
      exact
      component={ConductivContracts}
      path={routes.conductivContracts}
    />
    <Route
      exact
      component={ContractIntelligence}
      path={routes.contractIntelligence}
    />
    <Route
      exact
      component={ContractExplorer}
      path={`${routes.contractExplorer}/:contractId?`}
    />
    <Route exact component={MasterData} path={routes.masterData} />
    <Route exact component={Organizations} path={routes.organizations} />
    <Route exact component={Suppliers} path={routes.suppliers} />
    <Route render={() => <Redirect to={routes.root} />} />
  </Switch>
)
