/*
  @author Alistair Hill
  @description Supplier actions
*/
import {BasicSupplier, Supplier} from 'types'

const setSuppliers = (suppliers: BasicSupplier[]) =>
  ({
    type: 'SET_SUPPLIERS',
    payload: suppliers,
  } as const)

const setSupplierName = (supplier: BasicSupplier) =>
  ({
    type: 'SET_SUPPLIER_NAME',
    payload: supplier,
  } as const)

const addSupplier = (supplier: Supplier) =>
  ({
    type: 'ADD_SUPPLIER',
    payload: supplier,
  } as const)

const resetSuppliers = () =>
  ({
    type: 'RESET_SUPPLIERS',
  } as const)

const setSupplierSearchText = (txt: string) =>
  ({
    type: 'SET_SUPPLIER_SEARCH_TEXT',
    payload: txt,
  } as const)

const setSelectedSupplier = (supplier: BasicSupplier) =>
  ({
    type: 'SET_SELECTED_SUPPLIER',
    payload: supplier,
  } as const)

export type SupplierActions = ReturnType<
  | typeof setSuppliers
  | typeof setSupplierName
  | typeof resetSuppliers
  | typeof setSupplierSearchText
  | typeof addSupplier
  | typeof setSelectedSupplier
>

export {
  setSuppliers,
  setSupplierName,
  resetSuppliers,
  setSupplierSearchText,
  addSupplier,
  setSelectedSupplier,
}
