/* eslint-disable */
import * as Types from '../types.generated'

import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {fetchData} from 'utility/reactQuery'
export type UpdateUserRolesMutationVariables = Types.Exact<{
  userId: Types.Scalars['Int']
  roleIds: Array<Types.Scalars['Int']> | Types.Scalars['Int']
}>

export type UpdateUserRolesMutation = {
  __typename?: 'Mutation'
  updateUserRoles: boolean
}

export const UpdateUserRolesDocument = `
    mutation updateUserRoles($userId: Int!, $roleIds: [Int!]!) {
  updateUserRoles(userId: $userId, roleIds: $roleIds)
}
    `
export const useUpdateUserRolesMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateUserRolesMutation,
    TError,
    UpdateUserRolesMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateUserRolesMutation,
    TError,
    UpdateUserRolesMutationVariables,
    TContext
  >(
    ['updateUserRoles'],
    (variables?: UpdateUserRolesMutationVariables) =>
      fetchData<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>(
        UpdateUserRolesDocument,
        variables
      )(),
    options
  )
