/*
  @author Alistair Hill, Lindsay Gaudinier
  @description Categories APIs
*/
import {request, paramsWithOrgId} from 'api'

import {Category, BasicCategory} from 'types'

const categoriesUrl = '/api/categories'

const showTop = [
  'Medical Device Manufacturer',
  'Medical Equipment Manufacturer',
  'Non-Purchased Services',
]

export const getCategories = (orgId: number) => {
  return request()
    .get(categoriesUrl, paramsWithOrgId(orgId))
    .then(({data}) => {
      const cats = data?.sort((a: BasicCategory, b: BasicCategory) =>
        a.name.localeCompare(b.name)
      )
      const reduceInit = {
        top: [] as BasicCategory[],
        theRest: [] as BasicCategory[],
      }
      const split = cats.reduce(
        (state: typeof reduceInit, cat: BasicCategory, ix: number) => {
          if (showTop.includes(cat.name)) {
            state.top.push(cat)
          } else {
            state.theRest.push(cat)
          }
          return state
        },
        reduceInit
      )
      return split.top.concat(split.theRest)
    })
    .catch((error) => {
      throw error
    })
}

export const getCategory = (orgId: number, categoryId: number) => {
  return request()
    .get(`${categoriesUrl}/${categoryId}`, paramsWithOrgId(orgId))
    .then(({data}) => data as Category)
    .catch((error) => {
      throw error
    })
}

export const updateCategoryApi = (orgId: number, category: Category) => {
  return request()
    .put(
      `${categoriesUrl}/${category && category.id}`,
      category,
      paramsWithOrgId(orgId)
    )
    .then(({data}) => data)
    .catch((error) => {
      throw error
    })
}

export const addCategoryApi = (orgId: number, category: Category) => {
  return request()
    .post(`${categoriesUrl}`, category, paramsWithOrgId(orgId))
    .then(({data}) => data)
    .catch((error) => {
      throw error
    })
}
