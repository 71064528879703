/*
  @author Alistair Hill
  @description main API includes axios
*/
import axios, {AxiosRequestConfig} from 'axios'

import {getToken} from 'auth'
import {config} from 'config'

type AxiosRequestOptions = {
  baseURL?: string
  timeout?: number
}

const defaults: AxiosRequestOptions = {
  baseURL: config.adminGatewayUrl,
  timeout: 30000,
}

const authTokenInjector = (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  // Check and acquire a token before the request is sent
  return new Promise((resolve, reject) => {
    getToken().then((token: string) => {
      if (token !== '') {
        config.headers!.Authorization = `Bearer ${token}`
        resolve(config)
      } else {
        reject(config)
      }
    })
  })
}

const request = ({
  baseURL = config.adminGatewayUrl,
  timeout = 30000,
}: AxiosRequestOptions = defaults) => {
  const instance = axios.create({
    baseURL,
    timeout,
  })
  instance.interceptors.request.use(authTokenInjector)
  return instance
}

const paramsWithOrgId = (orgId: number, other?: Object) => {
  return {params: {organizationId: orgId, ...other}}
}

export {request, paramsWithOrgId}
