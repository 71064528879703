/*
  @author Alistair Hill
  @description navbar component
*/
import React, {useState, MouseEvent} from 'react'
import {withRouter, RouteComponentProps} from 'react-router'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import {Redirect} from 'react-router'
import {getMSALUserAccount} from 'auth'

import {routes} from 'router/router'

import styles from './navbar.module.scss'

const Navbar = (props: RouteComponentProps) => {
  const idTokenClaims = getMSALUserAccount() as any
  const labelersGroup = idTokenClaims?.idTokenClaims?.groups?.includes(
    '8644c8ce-1297-4042-8fee-1402d17b66cb'
  )
  const {history} = props
  const {
    location: {pathname},
  } = history

  const [CCButton, setCCButton] = useState<HTMLButtonElement | null>(null)
  const [CIButton, setCIButton] = useState<HTMLButtonElement | null>(null)
  const [dataButton, setDataButton] = useState<HTMLButtonElement | null>(null)
  const [orgButton, setOrgButton] = useState<HTMLButtonElement | null>(null)
  const [
    suppliersButton,
    setSuppliersButton,
  ] = useState<HTMLButtonElement | null>(null)

  const resetNavMenu = () => {
    setCCButton(null)
    setCIButton(null)
    setDataButton(null)
    setOrgButton(null)
    setSuppliersButton(null)
  }

  if (labelersGroup) return <Redirect to={routes.contractExplorer} />

  return (
    <aside className={styles.navbarContainer}>
      <Grid container spacing={1} direction='column' alignItems='center'>
        <Grid item>
          <Button
            onClick={() => history.push(routes.root)}
            className={
              pathname === routes.root ? styles.active : styles.notactive
            }>
            Home
          </Button>
          <Button
            onClick={
              pathname === routes.conductivContracts
                ? undefined
                : (e: MouseEvent<HTMLButtonElement>) =>
                    setCCButton(e.currentTarget)
            }
            className={
              pathname === routes.conductivContracts
                ? styles.active
                : styles.notactive
            }>
            Conductiv Contracts
          </Button>
          <Menu
            id='admin-panel-cc-menu'
            anchorEl={CCButton}
            keepMounted
            open={!!CCButton}
            onClose={() => setCCButton(null)}>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.conductivContracts,
                  state: {tabNum: 0},
                })
                resetNavMenu()
              }}>
              Documents
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.conductivContracts,
                  state: {tabNum: 1},
                })
                resetNavMenu()
              }}>
              Supplier Contracts
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.conductivContracts,
                  state: {tabNum: 2},
                })
                resetNavMenu()
              }}>
              Activation Requests
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.conductivContracts,
                  state: {tabNum: 3},
                })
                resetNavMenu()
              }}>
              Activations
            </MenuItem>
          </Menu>

          <Button
            onClick={
              pathname === routes.contractIntelligence
                ? undefined
                : (e: MouseEvent<HTMLButtonElement>) =>
                    setCIButton(e.currentTarget)
            }
            className={
              pathname === routes.contractIntelligence
                ? styles.active
                : styles.notactive
            }>
            Contract Intelligence
          </Button>
          <Menu
            id='admin-panel-ci-menu'
            anchorEl={CIButton}
            keepMounted
            open={!!CIButton}
            onClose={() => setCIButton(null)}>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.contractIntelligence,
                  state: {tabNum: 0},
                })
                resetNavMenu()
              }}>
              Available Reports
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.contractIntelligence,
                  state: {tabNum: 1},
                })
                resetNavMenu()
              }}>
              Report Requests
            </MenuItem>
          </Menu>

          <Button
            onClick={() => {
              history.push({
                pathname: routes.contractExplorer,
                state: {tabNum: 0},
              })
              resetNavMenu()
            }}
            className={
              pathname === routes.contractExplorer
                ? styles.active
                : styles.notactive
            }>
            Contract Explorer
          </Button>
          <Button
            onClick={
              pathname === routes.masterData
                ? undefined
                : (e: MouseEvent<HTMLButtonElement>) =>
                    setDataButton(e.currentTarget)
            }
            className={
              pathname === routes.masterData ? styles.active : styles.notactive
            }>
            Data Management
          </Button>
          <Menu
            id='admin-panel-master-data-menu'
            anchorEl={dataButton}
            keepMounted
            open={!!dataButton}
            onClose={() => setDataButton(null)}>
            <MenuItem
              onClick={() => {
                history.push({pathname: routes.masterData, state: {tabNum: 0}})
                resetNavMenu()
              }}>
              Facilities
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({pathname: routes.masterData, state: {tabNum: 1}})
                resetNavMenu()
              }}>
              Categories
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({pathname: routes.masterData, state: {tabNum: 2}})
                resetNavMenu()
              }}>
              Stakeholders
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({pathname: routes.masterData, state: {tabNum: 3}})
                resetNavMenu()
              }}>
              Cached Data
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({pathname: routes.masterData, state: {tabNum: 4}})
                resetNavMenu()
              }}>
              AI Prompts
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({pathname: routes.masterData, state: {tabNum: 5}})
                resetNavMenu()
              }}>
              Ascend/Surpass Data
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({pathname: routes.masterData, state: {tabNum: 6}})
                resetNavMenu()
              }}>
              Contracts
            </MenuItem>
            {/*
            <MenuItem
              onClick={() => {
                history.push({pathname: routes.masterData, state: {tabNum: 7}})
                resetNavMenu()
              }}>
              Suppliers
            </MenuItem>
            */}
          </Menu>
          <Button
            onClick={
              pathname === routes.organizations
                ? undefined
                : (e: MouseEvent<HTMLButtonElement>) =>
                    setOrgButton(e.currentTarget)
            }
            className={
              pathname === routes.organizations
                ? styles.active
                : styles.notactive
            }>
            Organizations
          </Button>
          <Menu
            id='admin-panel-organizations-menu'
            anchorEl={orgButton}
            keepMounted
            open={!!orgButton}
            onClose={() => setOrgButton(null)}>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.organizations,
                  state: {tabNum: 0},
                })
                resetNavMenu()
              }}>
              Feature Flags
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.organizations,
                  state: {tabNum: 1},
                })
                resetNavMenu()
              }}>
              User Management
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.organizations,
                  state: {tabNum: 2},
                })
                resetNavMenu()
              }}>
              Diversity
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.organizations,
                  state: {tabNum: 3},
                })
                resetNavMenu()
              }}>
              Profiles
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.organizations,
                  state: {tabNum: 4},
                })
                resetNavMenu()
              }}>
              New Organization
            </MenuItem>
          </Menu>
          <Button
            onClick={
              pathname === routes.suppliers
                ? undefined
                : (e: MouseEvent<HTMLButtonElement>) =>
                    setSuppliersButton(e.currentTarget)
            }
            className={
              pathname === routes.suppliers ? styles.active : styles.notactive
            }>
            Suppliers
          </Button>
          <Menu
            id='admin-panel-suppliers-menu'
            anchorEl={suppliersButton}
            keepMounted
            open={!!suppliersButton}
            onClose={() => setSuppliersButton(null)}>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.suppliers,
                  state: {tabNum: 0},
                })
                resetNavMenu()
              }}>
              Feature Flags
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.suppliers,
                  state: {tabNum: 1},
                })
                resetNavMenu()
              }}>
              Profiles
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.suppliers,
                  state: {tabNum: 2},
                })
                resetNavMenu()
              }}>
              User Management
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: routes.suppliers,
                  state: {tabNum: 3},
                })
                resetNavMenu()
              }}>
              Resources
            </MenuItem>
          </Menu>
          {/* <Button
                disabled
                onClick={() => history.push(routes.predictivePlan)}
                className={pathname === routes.predictivePlan ? styles.active : ''}>
                Predictive Plan
              </Button> */}
          {/* <Button
            disabled
            onClick={() => history.push(routes.security)}
            className={pathname === routes.security ? styles.active : ''}>
            Security
          </Button> */}
        </Grid>
      </Grid>
    </aside>
  )
}

export default withRouter(Navbar)
