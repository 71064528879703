/* eslint-disable */
import * as Types from '../types.generated'

import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {fetchData} from 'utility/reactQuery'
export type ConductivFeaturesQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type ConductivFeaturesQuery = {
  __typename?: 'Query'
  conductivFeatures: Array<{
    __typename?: 'ConductivFeatureFlag'
    feature: Types.ConductivFeaturesEnum
    enabled: boolean
  }>
}

export const ConductivFeaturesDocument = `
    query ConductivFeatures {
  conductivFeatures {
    feature
    enabled
  }
}
    `
export const useConductivFeaturesQuery = <
  TData = ConductivFeaturesQuery,
  TError = unknown
>(
  variables?: ConductivFeaturesQueryVariables,
  options?: UseQueryOptions<ConductivFeaturesQuery, TError, TData>
) =>
  useQuery<ConductivFeaturesQuery, TError, TData>(
    variables === undefined
      ? ['ConductivFeatures']
      : ['ConductivFeatures', variables],
    fetchData<ConductivFeaturesQuery, ConductivFeaturesQueryVariables>(
      ConductivFeaturesDocument,
      variables
    ),
    options
  )
