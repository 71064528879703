import {
  ODataFilterOption,
  ODataFilterObject,
  ODataSelect,
  ODataQueryParams,
  ODataOrderBy,
  ODataSort,
} from 'types'
import {isEmpty, infiniteCurry} from 'utility'
import {ValueOf} from 'types/valueOf'

export const toFilterObj = <T>(
  op: ODataFilterOption,
  key?: keyof T,
  compareValue?: ValueOf<T>
): ODataFilterObject<T> => ({
  key,
  op,
  compareValue,
})

const toFilter = <T>({key, op, compareValue}: ODataFilterObject<T>) =>
  `${String(key)} ${op} ${compareValue}`

export const toODataFilter = <T>(
  filters: Array<ODataFilterObject<T>> | ODataFilterObject<T>
) =>
  Array.isArray(filters)
    ? filters
        .reduce((prev, filter) => {
          const {op} = filter

          if (op === 'and' || op === 'or') {
            return `${prev} ${op}`
          } else {
            return `${prev} ${toFilter(filter)}`
          }
        }, '')
        .trim()
    : toFilter(filters as ODataFilterObject<T>)

export const toSelect = <T>(...select: ODataSelect<T>[]) => {
  const [first, ...rest] = select
  return rest.reduce(
    (prev, curr) => `${prev},${String(curr)}`,
    `${String(first)}`
  )
}

export const concatODataQuery = (odataQueryOptions: ODataQueryParams) => {
  if (isEmpty(odataQueryOptions)) return ''

  const [[key, value], ...rest] = Object.entries(odataQueryOptions)

  return rest.reduce((prev, [k, v]) => `${prev}&${k}=${v}`, `${key}=${value}`)
}

export const infSelect = <T>(...select: ODataSelect<T>[]) =>
  infiniteCurry(
    (prev: string, curr: string) => (!!prev ? `${prev}, ${curr}` : `${curr}`),
    ''
  )(select)

export const toOrderBy = <T>(...orderby: ODataOrderBy<T>[]) => {
  if (isEmpty(orderby)) return ''

  return orderby.reduce(
    (prev, [key, sort]) =>
      !!prev
        ? `${prev}, ${String(key)} ${sort || 'asc'}`
        : `${String(key)} ${sort || 'asc'}`,
    ''
  )
}

export const infOrderBy = <T>(...orderby: Array<keyof T | ODataSort>) =>
  infiniteCurry(
    (prev: string, curr: keyof T | ODataSort) =>
      curr === 'asc' || curr === 'desc'
        ? `${prev} ${String(curr)}`
        : !!prev
        ? `${prev}, ${String(curr)}`
        : `${String(curr)}`,
    ''
  )(...orderby)
