/*
  @author Alistair Hill
  @description Supplier reducer
*/
import produce from 'immer'
import {createSelector} from 'reselect'

import {SupplierActions} from 'store/actions'
import {BasicSupplier} from 'types'
import {ReduxStateType} from 'store'
import CurrentSupplier from 'utility/currentSupplier'

export type SupplierState = {
  suppliers: BasicSupplier[]
  loaded: boolean
  supplier?: BasicSupplier
  searchText: string
  count: number
  selected?: BasicSupplier
}

const initialState: SupplierState = {
  suppliers: [],
  loaded: false,
  supplier: undefined,
  searchText: '',
  count: 0,
  selected: undefined,
}

const supplierSelector = (state: ReduxStateType) => state.supplier.suppliers
const searchText = (state: ReduxStateType) => state.supplier.searchText

export const filterSuppliers = createSelector(
  [supplierSelector, searchText],
  (suppliers, searchTerm) => {
    return suppliers.filter((supplier: BasicSupplier) =>
      supplier.Name.match(new RegExp(searchTerm, 'i'))
    )
  }
)

export const supplierReducer = (
  state: SupplierState = initialState,
  action: SupplierActions
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_SUPPLIERS':
        if (CurrentSupplier.supplier) {
          draft.selected = CurrentSupplier.supplier
        }
        return {
          ...draft,
          suppliers: action.payload,
          loaded: true,
          count: action.payload?.length,
        }
      case 'SET_SUPPLIER_NAME':
        draft.suppliers[
          draft.suppliers.findIndex(
            (supplier: BasicSupplier) => supplier.Id === action.payload.Id
          )
        ].Name = action.payload.Name
        break
      case 'ADD_SUPPLIER':
        let currentCount = draft.count
        currentCount++

        return {
          ...draft,
          suppliers: [
            ...draft.suppliers,
            {Id: action.payload.id, Name: action.payload.name},
          ],
          count: currentCount,
        }
      case 'RESET_SUPPLIERS':
        return initialState
      case 'SET_SUPPLIER_SEARCH_TEXT':
        return {
          ...draft,
          searchText: action.payload,
        }
      case 'SET_SELECTED_SUPPLIER':
        // Set Singleton
        CurrentSupplier.supplier = action.payload
        draft.selected = action.payload
        break
      default:
        return draft
    }
  })
}
