import React from 'react'

import {useAccount, useMsal} from '@azure/msal-react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

function handleLogout(instance: any) {
  instance.logoutRedirect().catch((e: any) => {
    console.error(e)
  })
}

export const SignOutButton = () => {
  const {accounts, instance} = useMsal()
  const account = useAccount(accounts[0])

  return (
    <div>
      <Typography variant='body2'>{account?.name}</Typography>
      <Button
        size='large'
        variant='outlined'
        onClick={() => handleLogout(instance)}>
        Sign Out
      </Button>
    </div>
  )
}
