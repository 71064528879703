/*
  @author Alistair Hill
  @description Spinner from Material UI!
    <Spinner show={showSpinner} wholePage={false} />
*/
import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import styles from './spinner.module.scss'

type SpinnerTypes = {
  show: boolean
  wholePage: boolean
  zIndex?: number
}

const Spinner = ({show, wholePage, zIndex = 1201}: SpinnerTypes) => (
  <>
    {wholePage
      ? show && (
          <div className={styles.backdrop} style={{zIndex: zIndex}}>
            <CircularProgress color='inherit' />
          </div>
        )
      : show && (
          <CircularProgress
            style={{height: '20px', width: '20px'}}
            color='inherit'
          />
        )}
  </>
)

Spinner.defaultProps = {
  show: false,
  wholePage: true,
}

export default Spinner
