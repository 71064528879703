import {QueryCache, QueryClient} from '@tanstack/react-query'

import {getToken} from 'auth'
import {config} from 'config'

const DEFAULT_HEADERS: HeadersInit = {
  'Content-Type': 'application/json',
}

const withJwtHeader = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
  }
}

// Create a client
const defaultQueryClientOptions = {
  defaultOptions: {
    queries: {
      // Stay Fresh for 30 min
      staleTime: 1000 * 60 * 30,
      retry: 1,
    },
  },
  queryCache: new QueryCache(),
}

export const queryClient = new QueryClient(defaultQueryClientOptions)

const fetcherGenerator = (gqlUrl: string) => <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  return async () => {
    const token = await getToken()
    const res = await fetch(gqlUrl, {
      method: 'POST',
      headers: {
        ...DEFAULT_HEADERS,
        ...withJwtHeader(token),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    const json = await res.json()

    if (json.errors) {
      const {message} = json.errors[0] || 'Error..'
      throw new Error(message)
    }

    return json.data
  }
}

// custom fetchers for generator
export const fetchData = fetcherGenerator(`${config.adminGatewayUrl}graphql`)
