/*
  @author Alistair Hill
  @description Facilities APIs
*/
import {request, paramsWithOrgId} from 'api'

import {Facility} from 'types'

export type FacilitiesType = {
  data?: Facility[]
}

const facilityUrl = '/api/organizations/facilities'

export const getFacilities = (orgId: number) => {
  return request()
    .get(facilityUrl, paramsWithOrgId(orgId))
    .then((response: FacilitiesType) => response && response.data)
    .catch((error) => {
      throw error
    })
}

export const updateFacilityApi = (orgId: number, facility: Facility) => {
  return request()
    .put(
      `${facilityUrl}/${facility && facility.id}`,
      facility,
      paramsWithOrgId(orgId)
    )
    .then(({data}) => data)
    .catch((error) => {
      throw error
    })
}

export const addFacilityApi = (orgId: number, facility: Facility) => {
  return request()
    .post(facilityUrl, facility, paramsWithOrgId(orgId))
    .then(({data}) => data)
    .catch((error) => {
      throw error
    })
}
