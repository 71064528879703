/**
 * @author David Beard
 * @description Hook useful for debouncing values.
 * an example might be autocomplete or often changing user input that will trigger server side calls
 */
import {useState, useEffect} from 'react'

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(() => value)

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])

  return debouncedValue
}
