import React from 'react'
import {useConductivFeaturesQuery} from 'graphql/queries/ConductivFeatures.generated'
import {ConductivFeaturesEnum} from 'graphql/types.generated'

export const ConductivFeatureContext = React.createContext<
  ConductivFeaturesEnum[]
>([])

type ConductivFeatureProviderProps = {
  children: React.ReactNode
}

export const ConductivFeatureProvider = ({
  children,
}: ConductivFeatureProviderProps) => {
  const [conductivFeatures, setConductivFeatures] = React.useState<
    ConductivFeaturesEnum[]
  >([])

  useConductivFeaturesQuery(
    {},
    {
      refetchInterval: 1000 * 60 * 30, // 30 Min
      enabled: true,
      onSettled(data, error) {
        if (error) {
          throw error
        }

        setConductivFeatures(
          data?.conductivFeatures
            .filter((x) => x.enabled)
            .map((x) => x.feature) ?? []
        )
      },
    }
  )

  return (
    <ConductivFeatureContext.Provider value={conductivFeatures}>
      {children}
    </ConductivFeatureContext.Provider>
  )
}
