/*
  @author Alistair Hill
  @description Loading component
*/
import React from 'react'

import LoadingCat from '../icons/loadingCat/loadingCat'
import styles from './loading.module.scss'

const Loading = () => (
  <section className={styles.loadingContainer}>
    <LoadingCat />
  </section>
)

export default Loading
