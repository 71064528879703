/*
  @author Alistair Hill
  @description Category reducer
*/
import produce from 'immer'

import {createSelector} from 'reselect'
import {CategoryActions} from 'store/actions'
import {BasicCategory} from 'types'
import {ReduxStateType} from 'store'

export type CategoryState = {
  categories: BasicCategory[]
  loaded: boolean
  count: number
  searchText: string
}

const initialState: CategoryState = {
  categories: [],
  loaded: false,
  count: 0,
  searchText: '',
}

const categorySelector = (state: ReduxStateType) => state.category.categories
const searchText = (state: ReduxStateType) => state.category.searchText

export const filterCategories = createSelector(
  [categorySelector, searchText],
  (categories, searchTerm) => {
    return (
      categories &&
      categories.filter((category: BasicCategory) =>
        category.name.match(new RegExp(searchTerm, 'i'))
      )
    )
  }
)

export const categoryReducer = (
  state: CategoryState = initialState,
  action: CategoryActions
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_CATEGORIES':
        return {
          ...draft,
          categories: action.payload,
          loaded: true,
          count: action.payload?.length,
        }
      case 'UPDATE_CATEGORY':
        draft.categories[
          draft.categories.findIndex(
            (category: BasicCategory) => category.id === action.payload.id
          )
        ].name = action.payload.name
        break
      case 'ADD_CATEGORY':
        let currentCount = draft.count
        currentCount++

        return {
          ...draft,
          categories: [...draft.categories, action.payload],
          count: currentCount,
        }
      case 'RESET_CATEGORIES':
        return initialState
      case 'SET_CATEGORY_SEARCH_TEXT':
        return {
          ...draft,
          searchText: action.payload,
        }
      default:
        return draft
    }
  })
}
