/*
  @author David Beard, Alistair Hill
  @description
*/
import React, {StrictMode, Suspense, useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {InteractionType} from '@azure/msal-browser'
import {
  useMsal,
  useAccount,
  useMsalAuthentication,
  AuthenticatedTemplate,
} from '@azure/msal-react'
import {Snackbar} from '@mui/material'

import {getOrganizations} from 'api'
import Header from 'components/header/header'
import Loading from 'components/loading/loading'
import Spinner from 'components/spinner/spinner'
import {AdminPanelRouter} from 'router/router'
import {ReduxStateType} from 'store'
import {setOrganizations, setLoading, setSnackbar} from 'store/actions'
import {Organization} from 'types'
import {queryClient} from 'utility/reactQuery'
import {ConductivFeatureProvider} from 'store/context/ConductivFeaturesContext'

import styles from './app.module.scss'
import {getToken} from 'auth'
import '../../utility/appInsightsSetup'

const App = () => {
  useMsalAuthentication(InteractionType.Redirect)

  const {accounts} = useMsal()
  const account = useAccount(accounts[0])

  const dispatch = useDispatch()
  const {spinCounter, snackbarText, showSnackbar} = useSelector(
    (state: ReduxStateType) => state.global
  )

  useEffect(() => {
    if (account) {
      dispatch(setLoading(true))

      // get organizations
      getOrganizations()
        .then((orgs: Organization[]) => {
          dispatch(setLoading(false))
          dispatch(setOrganizations(orgs))
        })
        .catch((error) => {
          dispatch(setLoading(false))
          dispatch(setSnackbar(`Organizations ${error}`))
        })

      // Log the current User's JWT
      getToken().then(console.warn)
    }
  }, [account]) // eslint-disable-line

  return (
    <StrictMode>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <AuthenticatedTemplate>
            <QueryClientProvider client={queryClient}>
              <ConductivFeatureProvider>
                <Spinner show={spinCounter > 0} zIndex={1301} />
                <div className={styles.appContainer}>
                  <Header title='Admin Panel' />
                  <section className={styles.navAndPageContainer}>
                    <aside className={styles.pageContainer}>
                      <AdminPanelRouter />
                    </aside>
                  </section>
                </div>
                <Snackbar
                  open={showSnackbar}
                  autoHideDuration={5000}
                  onClose={() => dispatch(setSnackbar())}
                  message={snackbarText}
                />
                <ReactQueryDevtools />
              </ConductivFeatureProvider>
            </QueryClientProvider>
          </AuthenticatedTemplate>
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}

export default App
