/* eslint-disable */
import * as Types from '../types.generated'

import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {fetchData} from 'utility/reactQuery'
export type UpdateUserMutationVariables = Types.Exact<{
  user: Types.UserModelInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser?: {
    __typename?: 'User'
    id: number
    startMigration: boolean
    completeMigration: boolean
    disabled: boolean
  } | null
}

export const UpdateUserDocument = `
    mutation updateUser($user: UserModelInput!) {
  updateUser(user: $user) {
    id
    startMigration
    completeMigration
    disabled
  }
}
    `
export const useUpdateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserMutation,
    TError,
    UpdateUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateUserMutation,
    TError,
    UpdateUserMutationVariables,
    TContext
  >(
    ['updateUser'],
    (variables?: UpdateUserMutationVariables) =>
      fetchData<UpdateUserMutation, UpdateUserMutationVariables>(
        UpdateUserDocument,
        variables
      )(),
    options
  )
