import {BasicSupplier} from 'types'

class CurrentSupplier {
  private SupplierId: number | undefined
  private Supplier: BasicSupplier | undefined

  set supplier(supplier: BasicSupplier | undefined) {
    if (supplier?.Id) {
      this.Supplier = supplier
      this.SupplierId = supplier.Id
      sessionStorage.setItem('selectedSupplier', JSON.stringify(supplier))
    }
  }

  get supplier(): BasicSupplier | undefined {
    if (!this.Supplier) {
      try {
        const sessionSupplierStr = sessionStorage.getItem('selectedSupplier')
        if (sessionSupplierStr) {
          const supplier = JSON.parse(sessionSupplierStr)
          if (supplier) {
            this.Supplier = supplier
          }
        }
      } catch {
        this.Supplier = undefined
      }
    }

    return this.Supplier
  }

  get supplierId() {
    return this.SupplierId
  }
}

const currentSupplier = new CurrentSupplier()
export default currentSupplier
