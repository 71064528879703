/*
  @author Alistair Hill
  @description stakeholderGroup API
*/
import {request, paramsWithOrgId} from 'api'

import {StakeholderGroup, StakeholderGroupCategory} from 'types'

const stakeholdersUrl = '/api/stakeholdergroups'

export const getStakeholders = (orgId: number) => {
  return request()
    .get(stakeholdersUrl, paramsWithOrgId(orgId))
    .then(({data}: any) => data as StakeholderGroup[])
    .catch((error) => {
      throw error
    })
}

export const upsertStakeholderCategories = (
  orgId: number,
  stakeholderGroupCategories: StakeholderGroupCategory[]
) =>
  request()
    .put(
      `${stakeholdersUrl}/categories`,
      {stakeholderGroupCategories},
      paramsWithOrgId(orgId)
    )
    .then(({data}) => data)
    .catch((error) => {
      throw error
    })

export const upsertStakeholderGroup = (
  orgId: number,
  stakeholderGroup: StakeholderGroup
) => {
  return request()
    .put(`${stakeholdersUrl}`, stakeholderGroup, paramsWithOrgId(orgId))
    .then(({data}) => data)
    .catch((error) => {
      throw error
    })
}
