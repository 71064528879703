/*
  @author Alistair Hill
  @description header component
*/
import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import {SignOutButton} from 'components/auth/SignOutButton'
import Navbar from 'components/navbar/navbar'
import {useGetSuppliersPagingQuery} from 'graphql/queries/SuppliersPaging.generated'
import {ReduxStateType} from 'store'
import {setSelectedOrganization, setSelectedSupplier} from 'store/actions'
import {Organization} from 'types'
import {ascending} from 'utility'
import CurrentOrganization from 'utility/currentOrganization'
import CurrentSupplier from 'utility/currentSupplier'
import {useDebounce} from 'utility/customHooks/useDebounce'

import styles from './header.module.scss'

type Props = {
  title?: string
}

const Header = ({title}: Props) => {
  const {
    organizations,
    selected: orgSelected,
    loaded: orgsLoaded,
  } = useSelector((state: ReduxStateType) => state.organization)
  const {selected: supplierSelected} = useSelector(
    (state: ReduxStateType) => state.supplier
  )
  const {orgNotRequired} = useSelector((state: ReduxStateType) => state.global)
  const {supplierRequired} = useSelector(
    (state: ReduxStateType) => state.global
  )

  const [supplierSearchTerms, setSupplierSearchTerms] = useState('')
  const [supplier, setSupplier] = useState<
    | {
        id: number
        name?: string | null | undefined
      }
    | null
    | undefined
  >(null)

  const {
    data: suppliersData,
    refetch: refetchSuppliers,
  } = useGetSuppliersPagingQuery(
    {
      searchTerms: supplierSearchTerms,
    },
    {enabled: false}
  )

  const supplierSearchTermsUpdated = useDebounce(supplierSearchTerms, 400)

  useEffect(() => {
    refetchSuppliers()
  }, [supplierSearchTermsUpdated]) // eslint-disable-line

  //@ts-ignore
  const sortedOrgs = organizations?.slice().sort(ascending((o) => o.name))
  const dispatch = useDispatch()

  const handleSupplierInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setSupplierSearchTerms(value)
  }

  const handleSupplierChange = (
    event: React.SyntheticEvent<Element, Event>,
    value:
      | {
          id: number
          name?: string | null | undefined
        }
      | null
      | undefined
  ) => {
    setSupplier(value)
    if (value) {
      dispatch(setSelectedSupplier({Id: value.id, Name: value.name ?? ''}))
    }
  }

  return (
    <header className={styles.headerContainer}>
      <div className={styles.title}>
        <Typography variant='h1'>{title}</Typography>
        <Navbar />
      </div>
      <div
        className={styles.login}
        title={`Selected SupplierID: ${supplierSelected?.Id}`}>
        {supplierRequired ? (
          <Autocomplete
            autoHighlight={false}
            autoSelect={false}
            getOptionLabel={(x) => x?.name ?? ''}
            onChange={handleSupplierChange}
            onInputChange={handleSupplierInputChange}
            options={
              supplierSearchTerms !== '' &&
              suppliersData?.suppliersPaging?.nodes
                ? suppliersData?.suppliersPaging?.nodes
                : []
            }
            style={{width: 250, margin: '0 15px'}}
            defaultValue={
              CurrentSupplier.supplier
                ? {
                    id: CurrentSupplier.supplier.Id,
                    name: CurrentSupplier.supplier.Name,
                  }
                : {id: supplierSelected?.Id ?? 0, name: supplierSelected?.Name}
            }
            value={supplier}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder='Supplier'
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        ) : (
          !orgNotRequired &&
          sortedOrgs &&
          sortedOrgs.length > 0 && (
            <Autocomplete
              id='auto-complete-organizations'
              //@ts-ignore
              options={sortedOrgs}
              //@ts-ignore
              getOptionLabel={(option) =>
                option && `${option.name} [${option.id}]`
              }
              style={{width: 250, margin: '0 15px'}}
              onChange={(event, value) =>
                dispatch(setSelectedOrganization(value))
              }
              defaultValue={
                CurrentOrganization.organization
                  ? CurrentOrganization.organization
                  : orgSelected
              }
              disabled={!orgsLoaded}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Organization'
                  placeholder='Pick an organization'
                  variant='outlined'
                  fullWidth
                />
              )}
              isOptionEqualToValue={(
                option: Organization,
                value: Organization
              ) => {
                return option?.id === value?.id
              }}
            />
          )
        )}
        <SignOutButton />
      </div>
    </header>
  )
}

export default Header
