/*
  @author Alistair Hill
  @description Organizations reducer
*/
import produce from 'immer'

import {OrganizationActions} from 'store/actions'
import {Organization} from 'types'
import CurrentOrganization from 'utility/currentOrganization'

export type OrganizationState = {
  organizations?: Organization[]
  loaded: boolean
  selected?: Organization
}

const globalOrganization: Organization = {
  id: 0,
  name: 'GLOBAL',
  savingsGoals: [],
}

const initialState: OrganizationState = {
  organizations: [],
  loaded: false,
  selected: undefined,
}

export const organizationReducer = (
  state: OrganizationState = initialState,
  action: OrganizationActions
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_ORGANIZATIONS':
        if (CurrentOrganization.organization) {
          draft.selected = CurrentOrganization.organization
        }

        draft.organizations = [globalOrganization, ...action.payload]
        draft.loaded = true
        break
      case 'SET_SELECTED_ORGANIZATION':
        // Set Singleton
        CurrentOrganization.organization = action.payload
        draft.selected = action.payload
        break
    }
  })
}
