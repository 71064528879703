/*
  @author David Beard, Alistair Hill
  @description Organization APIs
*/
import {request} from 'api'
import {
  UpdateFeatureFlagsMutation,
  UpdateFeatureFlagsMutationVariables,
  UpdateFeatureFlagsDocument,
} from 'graphql/mutations/FeatureFlags.generated'
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserDocument,
} from 'graphql/mutations/User.generated'
import {
  UpdateUserRolesMutation,
  UpdateUserRolesMutationVariables,
  UpdateUserRolesDocument,
} from 'graphql/mutations/UserRoles.generated'

import {FeatureFlag} from 'types/featureFlag'
import {fetchData} from 'utility/reactQuery'
import {UserModelInput} from 'graphql/types.generated'

const getOrgsUrl = 'api/Organizations'

export const getOrganizations = () =>
  request()
    .get(getOrgsUrl)
    .then((res) => res.data)

export const updateFeatureFlags = (
  organizationId: number,
  featureFlags: FeatureFlag[]
) => {
  const variables: UpdateFeatureFlagsMutationVariables = {
    organizationId,
    featureFlags,
  }

  return fetchData<
    UpdateFeatureFlagsMutation,
    UpdateFeatureFlagsMutationVariables
  >(UpdateFeatureFlagsDocument, variables)()
}

export const updateUser = (user: UserModelInput) => {
  const variables: UpdateUserMutationVariables = {
    user,
  }

  return fetchData<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    variables
  )()
}

export const updateUserRoles = (userId: number, roleIds: number[]) => {
  const variables: UpdateUserRolesMutationVariables = {
    userId,
    roleIds,
  }

  return fetchData<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>(
    UpdateUserRolesDocument,
    variables
  )()
}
