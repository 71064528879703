/*
  @author Alistair Hill
  @description Facility reducer
*/
import produce from 'immer'
import {createSelector} from 'reselect'

import {FacilityActions} from 'store/actions'
import {Facility} from 'types'
import {ReduxStateType} from 'store'

export type FacilityState = {
  facilities: Facility[]
  loaded: boolean
  searchText: string
  count: number
}

const initialState: FacilityState = {
  facilities: [],
  loaded: false,
  count: 0,
  searchText: '',
}

const facilitiesSelector = (state: ReduxStateType) => state.facility.facilities
const searchText = (state: ReduxStateType) => state.facility.searchText

export const filterFacilities = createSelector(
  [facilitiesSelector, searchText],
  (facilities, searchTerm) => {
    return facilities.filter((facility: Facility) =>
      facility.name.match(new RegExp(searchTerm, 'i'))
    )
  }
)

export const facilityReducer = (
  state: FacilityState = initialState,
  action: FacilityActions
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SET_FACILITIES':
        return {
          ...draft,
          facilities: action.payload,
          loaded: true,
          count: action.payload?.length,
        }
      case 'UPDATE_FACILITY':
        draft.facilities[
          draft.facilities.findIndex(
            (facility: Facility) => facility.id === action.payload.id
          )
        ] = action.payload
        break
      case 'ADD_FACILITY':
        let currentCount = draft.count
        currentCount++

        return {
          ...draft,
          facilities: [...draft.facilities, action.payload],
          count: currentCount,
        }
      case 'SET_FACILITY_SEARCH_TEXT':
        return {
          ...draft,
          searchText: action.payload,
        }
      case 'RESET_FACILITIES':
        return initialState
      default:
        return draft
    }
  })
}
