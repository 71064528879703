/*
  @author Alistair Hill
  @description Organization actions
*/
import {Organization} from 'types'

const setOrganizations = (organizations: Organization[]) =>
  ({
    type: 'SET_ORGANIZATIONS',
    payload: organizations,
  } as const)

const setSelectedOrganization = (organization: Organization) =>
  ({
    type: 'SET_SELECTED_ORGANIZATION',
    payload: organization,
  } as const)

export type OrganizationActions = ReturnType<
  typeof setOrganizations | typeof setSelectedOrganization
>

export {setOrganizations, setSelectedOrganization}
